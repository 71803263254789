<template>
    <a-modal
        :okButtonProps="{ disabled: true }"
        :visible="showLicenseScan"
        :title="`Scan License`"
        class="modal-large"
        @ok="scanToDetails"
        @cancel="$emit('close')"
        okText="NEXT"
        cancelText="CANCEL"
        centered
    >
        <a-card
            v-if="type === 'dl'"
            class="my-3"
            :class="{
                'border-success':
                    purchaserScan.fileFront && !purchaserScan.errorFront,
                'border-danger':
                    purchaserScan.errorFront ||
                    (showScanError && !purchaserScan.fileFront),
            }"
        >
            <div class="dF jSB aC">
                <span>
                    <span>
                        Front Photo
                        <a-icon
                            v-if="
                                purchaserScan.errorFront ||
                                (showScanError && !purchaserScan.fileFront)
                            "
                            type="close"
                            class="text-danger"
                        />
                    </span>
                    <small
                        class="block text-sm"
                        v-if="
                            purchaserScan.errorFront ||
                            (showScanError && !purchaserScan.fileFront)
                        "
                        >There was a problem retrieving data. Please scan
                        again.</small
                    >
                </span>
                <div>
                    <a-upload
                        :showUploadList="false"
                        :beforeUpload="(e) => beforeScan(e, 'front')"
                    >
                        <a-button
                            v-if="!purchaserScan.fileFront"
                            type="secondary"
                            icon="scan"
                            >Scan</a-button
                        >
                        <a-icon v-else type="check" class="text-success" />
                    </a-upload>
                </div>
            </div>
        </a-card>
        <a-card
            :key="newTime"
            class="my-3"
            :class="{
                'border-success':
                    purchaserScan.data.firstName && !purchaserScan.errorBack,
                'border-danger':
                    purchaserScan.errorBack ||
                    (showScanError && !purchaserScan.fileBack),
            }"
        >
            <div class="dF jSB aC">
                <span>
                    <span>
                        Back Photo
                        <a-icon
                            v-if="
                                purchaserScan.errorBack ||
                                (showScanError && !purchaserScan.fileBack)
                            "
                            type="close"
                            class="text-danger"
                        />
                    </span>
                    <small
                        class="block text-sm"
                        v-if="
                            purchaserScan.errorBack ||
                            (showScanError && !purchaserScan.fileBack)
                        "
                        >There was a problem retrieving data. Please scan
                        again.</small
                    >
                    <small
                        class="block text-sm"
                        v-else-if="purchaserScan.data.firstName"
                        >{{ whoName }}:
                        <strong
                            >{{ purchaserScan.data.firstName }}
                            {{ purchaserScan.data.lastName }}</strong
                        ></small
                    >
                </span>
                <div>
                    <a-upload
                        :showUploadList="false"
                        :beforeUpload="(e) => beforeScan(e, 'back')"
                        v-if="!purchaserScan.data.firstName"
                    >
                        <a-button type="secondary" icon="scan">Scan</a-button>
                    </a-upload>
                    <a-icon v-else type="check" class="text-success" />
                </div>
            </div>
        </a-card>
    </a-modal>
</template>

<script>
export default {
    props: ["options", "type", "whoName"],
    data: () => ({
        newTime: Date.now(),
        showScanError: false,
        purchaserScan: {
            errorFront: false,
            errorBack: false,
            fileFront: null,
            fileBack: null,
            data: {},
        },
    }),
    computed: {
        showLicenseScan() {
            return this.options.showLicenseScan;
        },
    },
    watch: {
        showLicenseScan(val) {
            if (!val) {
                this.purchaserScan = {
                    errorFront: false,
                    errorBack: false,
                    fileFront: null,
                    fileBack: null,
                    data: {},
                };
            }
        },
    },
    methods: {
        titleCase(d) {
            return d[0] + d.substr(1).toLowerCase();
        },
        scanToDetails() {
            if (this.scanError())
                return this.$message.error(
                    "Please review your images and try again"
                );

            let data = this.purchaserScan.data;
            let dateFields = ["dob", "licenseExpirationDate", "issueDate"];

            dateFields.forEach((field) => {
                if (data[field] && data[field].length > 5) {
                    let date = data[field] + "";
                    let dateSplit = date.split("");
                    let firstTwo = dateSplit[0] + dateSplit[1];
                    firstTwo = +firstTwo;
                    if (firstTwo > 12) {
                        // ca
                        let year = dateSplit.splice(0, 4).join("");
                        let month = dateSplit.splice(0, 2).join("");
                        let day = dateSplit.join("");
                        data[field] = year + "-" + month + "-" + day;
                    } else {
                        // us
                        let month = dateSplit.splice(0, 2).join("");
                        let day = dateSplit.splice(0, 2).join("");
                        let year = dateSplit.join("");
                        data[field] = year + "-" + month + "-" + day;
                    }
                }
            });

            let titleFields = [
                "firstName",
                "lastName",
                "middleName",
                "city",
                "region",
                "country",
            ];
            titleFields.forEach((field) => {
                if (data[field]) data[field] = this.titleCase(data[field]);
            });
            if (data.address1) {
                data.address1 = data.address1
                    .split(" ")
                    .map(this.titleCase)
                    .join(" ");
            }

            let {
                firstName,
                lastName,
                country,
                id = "",
                issueData = "",
                dob,
                city,
                region,
                middleName,
                address1,
                postal,
                licenseExpirationDate,
            } = data;

            let countries = {
                can: "Canada",
                usa: "America",
                us: "America",
            };

            if (country && country.length) {
                country = country.toLowerCase();
            }

            let purId = Date.now();

            let fileFront = this.purchaserScan.fileFront;
            let fileBack = this.purchaserScan.fileBack;

            let purchaser = {
                fileFront,
                fileBack,
                id: purId,
                firstName,
                middleName: "",
                lastName,
                email: "",
                phone: "",
                dob,
                company: "",
				occupation: "",
                business: "",
                cell: "",
                idType: "pp",
                idValue: id,
				passportOtherValue: "",
				passportOtherIssuing: "",
				passportOtherIssuingCountry: "",
				passportOtherExpiry: "",
				prValue: '',
				prIssuing: '',
				prIssuingCountry: '',
				prExpiry: '',
				wpValue: '',
				wpIssuing: '',
				wpIssuingCountry: '',
				wpExpiry: '',
				bcValue: '',
				bcIssuing: '',
				bcIssuingCountry: '',
				otherName: '',
				otherValue: '',
				otherIssuing: '',
				otherIssuingCountry: '',
				otherExpiry: '',
                birthPlace: '',
                registrationDate: '',
                issueDate: '',
                address1,
                address2: "",
                country: countries[country] ? countries[country] : country,
                region,
                city,
                jobTitle: "",
				ssnsin: "",
                issuing: region,
				issuingCountry: "",
                expiry: licenseExpirationDate,
                postal,
            };

            this.purchaserScan = {
                errorFront: false,
                errorBack: false,
                fileFront: null,
                fileBack: null,
                data: {},
            };
            if (this.options.callback) this.options.callback(purchaser);
            this.$emit("close");
        },
        scanError() {
            this.showScanError = false;
            if (
                !this.purchaserScan.fileFront ||
                !this.purchaserScan.data.firstName
            ) {
                this.showScanError = true;
                return true;
            }
            return false;
        },
        scanned(type, file, data) {
            if (this.scanTimer) clearTimeout(this.scanTimer);
            window.callbackScanning = null;
            if (type) this.$store.commit("LOAD", false);

            if (type === "back") {
                this.purchaserScan.errorBack = false;
                this.purchaserScan.fileBack = null;
                if (data.firstName) {
                    this.purchaserScan.fileBack = file;
                    Object.keys(data).forEach((key) => {
                        let purchaserKey = key;

                        if (key === "postalCode") {
                            purchaserKey = "postal";
                        }
                        this.purchaserScan.data[purchaserKey] = data[key]
                            .trim()
                            .replace(/,/g, "");
                    });
                    this.newTime = Date.now();
                } else {
                    this.purchaserScan.errorBack = true;
                }
            } else if (type === "front") {
                // fileFront
                this.purchaserScan.fileFront = file;
            }
            this.$store.commit("LOAD", false);
        },
        beforeScan(file, type) {
            if (type) this.$store.commit("LOAD", true);
            if (type === "back") {
                window.callbackScanning = (data) =>
                    this.scanned(type, file, data);
                DSScanner.DecodeImage(file);
                this.scanTimer = setTimeout(() => {
                    if (window.callbackScanning) {
                        window.callbackScanning = null;
                        this.scanned(type, file, {});
                    }
                }, 3000);
            } else if (type === "front") {
                this.scanned(type, file);
            }
            return false;
        },
    },
};
</script>

<style>
</style>
